import React from 'react'
import { Link } from 'gatsby'

import imgHero from '../assets/images/home-hero.png'

import SEO from '../components/SEO'
import Header from '../components/Header'

import { colors, theme } from '../constants/theme'

import { TitleM, ParagraphL } from '../styles/Typography'
import NotFoundPageLayout from '../styles/NotFoundPageLayout'

const NotFoundPage = () => (
  <>
    <SEO title="Mint and Square | Not Found" image={imgHero} />
    <Header
      logoBackground={colors.blue}
      logoBorder={colors.blue}
      linkText={colors.blue}
      menuBackground={colors.blue}
      menuLines={colors.white}
    />
    <NotFoundPageLayout>
      <TitleM>Ups, ta stran ne obstaja</TitleM>
      <Link to="/">
        <ParagraphL font={theme.fontSecondary}>← pojdi nazaj domov</ParagraphL>
      </Link>
    </NotFoundPageLayout>
  </>
)

export default NotFoundPage
