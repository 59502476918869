import styled from 'styled-components'
import Page from '../components/Page'

const NotFoundPageLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 85vh;
  min-height: 730px;
`

export default NotFoundPageLayout
